<template>
  <header class="header">
    <div class="header__arrow">
      {{ arrow }}
    </div>
    <h2 class="header__title">
      <strong>{{ short }}</strong> - {{ title }}
    </h2>
    <minichart v-if="type" :score="score" :max="max" />
    <div v-if="buttons" class="header__buttons">
      <slot></slot>
    </div>
  </header>
</template>


<script>
import MiniChart from '@/components/MiniChart.vue';

export default {
   components: {
    'minichart': MiniChart
  },

  emits: ['buttonaction'],

  props: {
    arrow: String,
    short: String,
    title: String,
    type: String,
    buttons: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    storeData() {
      return this.$store.state[this.type] || {};
    },

    score() {
      return this.calculateScore(this.storeData);
    },

    max() {
      return this.calculateMaxScore(this.storeData);
    }
  },

  methods: {

    calculateScore(data) {
      return Math.round(
        data.reduce(
          (total, section) => total + section.fields.reduce(
            (score, field) => score + field.score
            , 0)
          , 0)
        );
    },

    calculateMaxScore(data) {
      return Math.round(
        data.reduce(
          (total, section) => total + section.fields.reduce(
            (score, field) => score + field.best
            , 0)
          , 0)
        );
    },

    buttonAction() {
      this.$emit('buttonaction');
    },

  }
}
</script>


