import { createStore } from 'vuex'

import calculateScore from '@/modules/score.js';

export default new createStore({
  state: {

    pd: [
      {
        title: 'Training',
        fields: [
          {
            key: 'o2cworkshops',
            label: 'O2C Workshops',
            type: 'number',
            worst: -10, best: 10, value: null, score: null, fn: 'pd',
            text: `
              <p>The measure is the percentage of the SSC based O2C team that has participated in a workshop, relevant to their current job provided by an external provider in the last 12 months e.g Collection skills, Risk Analysis.</p>
              <p>75% or more is considered good.</p>
            `
          },
          {
            key: 'o2ccert',
            label: 'Callisto Grand O2C Cert',
            type: 'number',
            worst: -10, best: 10, value: null, score: null, fn: 'pd',
            text: `
              <p>Percentage of SSC based O2C team that have participated in the CALLISTO GRAND O2C CERTIFICATE qualification.</p>
              <p>All O2C team members within the scope of the Certificate  includes e.g:</p>
              <ul>
                <li>Cash Application</li>
                <li>Invoicing </li>
                <li>Collections</li>
                <li>Risk</li>
                <li>Customer data base</li>
                <li>Dispute management</li>
              </ul>
              <p>75% or more is considered very good.<br>Excellent = 100 %</p>
            `
          },
          {
            key: 'o2cdiploma',
            label: 'Callisto Grand O2C Diploma',
            type: 'number',
            worst: -10, best: 10, value: null, score: null, fn: 'pd',
            text: `
              <p>All O2C team members are invited but focus is on Team Leaders , aspiring Team Leaders and Managers in all O2C departments, e.g.:</p>
              <ul>
                <li>Cash Application</li>
                <li>Invoicing </li>
                <li>Collections</li>
                <li>Risk</li>
                <li>Customer data base</li>
                <li>Dispute management</li>
              </ul>
              <p>75 % or more Team Leaders and Managers is considered very good.<br>100 % is excelent.</p>
            `
          },
          {
            key: 'induction',
            label: 'Induction',
            type: 'number',
            worst: -1, best: 1, value: null, score: null, fn: 'pd',
            text: `
              <p>Percentage of all SSC based O2C team that have received an induction programme within 2 months of joining the company.</p>
            `
          },
          {
            key: 'erptraining',
            label: 'ERP Training - External',
            type: 'number',
            worst: -4, best: 4, value: null, score: null, fn: 'pd',
            text: `
              <p>Percentage of all SSC based O2C team that have received an ERP training , relevant to their job within 12 months of joining the company .Training to be from an external service provider or independent internal source.</p>
              <p>75% or more is considered very good.</p>
            `
          }
        ]
      },
      {
        title: 'Engagement',
        fields: [
          {
            key: 'clientvisits',
            label: 'Client Visits',
            type: 'number',
            worst: -4, best: 4, value: null, score: null, fn: 'pd',
            text: `
              <p>Percentage of SSC based O2C Team Leaders and Managers that have visited at least one external customer within the last 12 months.</p>
              <p>75% or more is considered very good.</p>
            `
          },
          {
            key: 'factoryvisits',
            label: 'Factory Visits',
            type: 'number',
            worst: -4, best: 4, value: null, score: null, fn: 'pd',
            text: `
              <p>Percentage of all SSC based O2C Team Leaders and Managers that have visited a company manufacturing site or equivalent within the last 12 months.</p>
              <p>75% or more is considered Very Good. </p>
            `
          },
          {
            key: 'productknowledge',
            label: 'Product Knowledge',
            type: 'number',
            worst: -1, best: 1, value: null, score: null, fn: 'pd',
            text: `
              <p>Percentage of all SSC based O2C teams that have received company product knowledge / familiarisation within 2 months of joining.</p>
            `
          }
        ]
      },
      {
        title: 'Empowerment',
        fields: [
          {
            key: 'writeoffs',
            label: 'Write Offs',
            type: 'number',
            worst: -2, best: 2, value: null, score: null, fn: 'pd',
            text: `
              <p>Team leaders and Managers that can approve write offs up to the level stated in the Credit Policy manual.</p>
              <p>If no level is approved for SSC team Team Leaders and above then score is zero. </p>
            `
          },
          {
            key: 'customercontact',
            label: 'Customer Contact',
            type: 'number',
            worst: -2, best: 2, value: null, score: null, fn: 'pd',
            text: `
              <p>Percentage of all  SSC based O2C teams empowered to contact customers directly without the need to ask permission.</p>
            `
          },
          {
            key: 'budgetresponsibility',
            label: 'Budget Responsibility',
            type: 'number',
            worst: -4, best: 4, value: null, score: null, fn: 'pd',
            text: `
              <p>For all O2C team members that have the title 'Manager' or equivalent in their job title.</p>
              <p>Budget to be a minimum of Eur 500 per team member to include training,  travel, conferences etc.</p>
            `
          },
          {
            key: 'seniormtng',
            label: 'Senior Mgmt Mtng (ECC)',
            type: 'checkbox',
            worst: -4, best: 4, value: false, score: -4, fn: 'bool',
            text: `
              <p>Measure is the company has set up an Executive Credit Committee (as described in O2C Certificate ) and there is clear evidence of regular participation from Team Leaders and Managers.</p>
              <p>ECC is the regular scheduled meeting where O2C T/L and Managers meet with Sales, Finance and other operational executives.</p>
            `
          }
        ]
      },
    ],

    pp: [
      {
        title: 'Technology',
        fields: [
          {
            key: 'riskmanagement',
            label: 'Risk Management',
            type: 'checkbox',
            worst: -6, best: 6, value: false, score: -6, fn: 'bool',
            text: `
              <p>Best practice is that a 3rd party Risk data company interface credit limits and parent / child analytics into the ERP</p>
            `
          },
          {
            key: 'einvoicing',
            label: 'E-Invoicing',
            type: 'checkbox',
            worst: -6, best: 6, value: false, score: -6, fn: 'bool',
            text: `
              <p>Best practice is zero paper invoices and associated documents are made available  via E-Invoicing  third party specialist and that all O2C team members can directly access all related documents.</p>
            `
          },
          {
            key: 'collections',
            label: 'Collections',
            type: 'checkbox',
            worst: -6, best: 6, value: false, score: -6, fn: 'bool',
            text: `
              <p>Collections and Cash Allocation to be directly connected for BI and AI analytics and reporting</p>
            `
          },
          {
            key: 'cashapp',
            label: 'Cash App',
            type: 'checkbox',
            worst: -6, best: 6, value: false, score: -6, fn: 'bool',
            text: `
              <p>Collections and Cash Allocation to be directly connected for BI and AI analytics and reporting</p>
            `
          },
          {
            key: 'disputes',
            label: 'Disputes',
            type: 'checkbox',
            worst: -6, best: 6, value: false, score: -6, fn: 'bool',
            text: `
              <p>Advice of disputes and workflows to interface with all sources logging disputes directly within the AR ledger. Can   Collections immediately see which invoices are disputed and extract various reports.</p>
              <p>Only 100% equals YES.</p>
            `
          }
        ]
      },
      {
        title: 'Portfolio Management',
        fields: [
          {
            key: 'parentchild',
            label: 'Parent/ Child Analysis',
            type: 'checkbox',
            worst: -10, best: 10, value: false, score: -10, fn: 'bool',
            text: `
              <p>Is there a process in place linked to a service provider that interfaces a unique relationship number into the ERP.</p>
              <p>This is to confirm all parent/child relationships in the client portfolio.</p>
              <p>Only 100% complete process qualifies as YES.</p>
            `
          },
          {
            key: 'portfoliosegmentation',
            label: 'Portfolio Segmentation',
            type: 'checkbox',
            worst: -10, best: 10, value: false, score: -10, fn: 'bool',
            text: `
              <p>Are the ledgers segmented in the line with the parent/ child analysis.</p>
              <p>Only 100% complete process qualifies as YES.</p>
            `
          },
          {
            key: 'dunningprocess',
            label: 'Dunning Process',
            type: 'checkbox',
            worst: -10, best: 10, value: false, score: -10, fn: 'bool',
            text: `
              <p>Is there an automated Dunning process in place aligned with the parent / child relationship and portflio segmentation.</p>
              <p>Only 100% complete process qualifies as YES.</p>
            `
          },
          {
            key: 'thirdparty',
            label: 'Third Party Collections',
            type: 'checkbox',
            worst: -4, best: 4, value: false, score: -4, fn: 'bool',
            text: `
              <p>Company employs a 3rd party collection agent integrated automatically for a designated range of customers within the Credit Policy and Collection Strategies.</p>
            `
          }
        ]
      },
      {
        title: 'Documents',
        fields: [
          {
            key: 'creditpolicy',
            label: 'Credit Policy',
            type: 'number',
            worst: -4, best: 4, value: null, score: null, fn: 'pp',
            text: `
              <p>Percentage of SSC O2C teams (all functions)  that have received a copy of this document and there is a signed record of receipt.</p>
            `
          },
          {
            key: 'termsconditions',
            label: 'Terms & Conditions',
            type: 'number',
            worst: -4, best: 4, value: null, score: null, fn: 'pp',
            text: `
              <p>Percentage of O2C teams (all functions) that have been given a copy of this document and there is a signed record of receipt.</p>
            `
          },
          {
            key: 'sla',
            label: 'SLA',
            type: 'number',
            worst: -4, best: 4, value: null, score: null, fn: 'pp',
            text: `
              <p>Percentage of O2C teams (all functions) that have been given a copy of this document ( relevant to O2C) and there is a signed record of receipt.</p>
            `
          },
          {
            key: 'annualreport',
            label: 'Annual Report',
            type: 'number',
            worst: -4, best: 4, value: null, score: null, fn: 'pp',
            text: `
              <p>Percentage of O2C teams (all functions) that have been given a copy of this document and there is a signed record of receipt.</p>
            `
          }
        ]
      },
    ],

    kpi: [
      {
        title: 'Collections',
        fields: [
          {
            key: 'overdue',
            label: '% Overdue',
            type: 'number',
            worst: -10, best: 10, value: null, score: null, fn: 'kpi',
            text: `
              <p>2.5 % or less overdue is considered very good.<br>
              Perfect = 0 zero.</p>
            `
          },
          {
            key: 'wovssales',
            label: '% W/O vs Sales',
            type: 'number',
            worst: -4, best: 4, value: null, score: null, fn: 'kpi',
            text: `
              <p>The measure is the current financial year.</p>
              <p>2.5 % or less is considered very good.<br>
              Perfect = zero %.</p>
            `
          }
        ]
      },
      {
        title: 'Cash App',
        fields: [
          {
            key: 'unallocated',
            label: '% Unallocated',
            type: 'number',
            worst: -10, best: 10, value: null, score: null, fn: 'kpi',
            text: `
              <p>Scope is all cash received in bank accounts and posted to AR subledger.</p>
              <p>First check is that bank and AR balance.</p>
              <p>2.5% total unapplied cash on AR subledger or less is considered very good.<br>
              Perfect = 0 zero.</p>
            `
          }
        ]
      },
      {
        title: 'Disputes',
        fields: [
          {
            key: 'creditnotes',
            label: 'Credit Notes as % of Sales',
            type: 'number',
            worst: -10, best: 10, value: null, score: null, fn: 'kpi',
            text: `
              <p>Measure is % quantity of credit notes raised as a percentage of invoices in the current financial year.</p>
              <p>Any other deductions e.g. inter company or rebates are excluded.</p>
              <p>2.5% or less is considered very good.<br>Perfect = 0 zero.</p>
            `
          }
        ]
      },
      {
        title: 'Talent',
        fields: [
          {
            key: 'o2cstaff',
            label: '% O2C Staff Turnover',
            type: 'number',
            worst: -10, best: 10, value: null, score: null, fn: 'kpi',
            text: `
              <p>Measure is rolling 12 months.</p>
              <p>Staff turnover includes all team members that have left the O2C team either to other departments or out of the company.</p>
              <p>2.5% or less is considered very good.<br>Perfect = 0 zero.</p>
            `
          }
        ]
      },
    ],

  },

  getters: {
    encodedCPMValues(state) {

      const sections = ['pd', 'pp', 'kpi']

      const data = {
        v: 1,   //version
        d: {},     // data
      }

     sections.forEach(section => {
        data.d[section] = []
        for (let subsection = 0; subsection < state[section].length; subsection++) {
          data.d[section][subsection] = []
          for (let field = 0; field < state[section][subsection].fields.length; field++) {
            data.d[section][subsection][field] = state[section][subsection].fields[field].value
          }
        }
      })

      return btoa(JSON.stringify(data))
    },
  },

  mutations: {
    updateField(state, item) {
      let idx = -1;
      let field;

      // vybereme objekt, ve kterém hledáme (seznam polí pro jednu obrazovku)
      const sections = state[item.measure];

      // projdeme všechny sekce
      for (let i = 0; i < sections.length; i++) {
        idx = sections[i].fields.findIndex(fld => fld.key === item.key);
        if (idx >= 0) {
          field = sections[i].fields[idx];
          break;
        }
      }

      if (field) {
        // update field
        field.value = item.value;
        field.score = calculateScore(field, item.value);
      } else {
        // error
        console.error(`Field ${item.key} not found`);
        return;
      }
    },


    decodeAndSetCPMValues(state, encodedValues) {
      const data = JSON.parse(atob(encodedValues))

      const sections = ['pd', 'pp', 'kpi']

      sections.forEach(section => {
        for (let subsectionIdx = 0; subsectionIdx < state[section].length; subsectionIdx++) {
          for (let fieldIdx = 0; fieldIdx < state[section][subsectionIdx].fields.length; fieldIdx++) {
            const field = state[section][subsectionIdx].fields[fieldIdx]
            field.value = data.d[section][subsectionIdx][fieldIdx]
            field.score = calculateScore(field, field.value);
          }
        }
      })
    },
  },


  actions: {
    updateField({ commit }, item) {
      commit('updateField', item);
    },

    decodeAndSetCPMValues({ commit }, encodedValues) {
      commit('decodeAndSetCPMValues', encodedValues)
    }
  },


  modules: {
  }
})
