<template>
  <main class="main">

    <header-bar
      arrow="EXCELLENCE"
      short="KPI"
      title="Key Performance Indicators"
      type="kpi"
    />

    <grid measure="kpi" />

  </main>
</template>


<script>
import Header from '@/components/Header.vue';
import Grid from '@/components/Grid.vue';

export default {
  components: {
    'header-bar': Header,
    'grid': Grid
  }
}
</script>