<template>
	<div class="grid__item">

		<div class="grid__label">
			{{ field.label }}
		</div>

		<div class="grid__help">
			<img
				v-if="!readonly"
				class="grid__help-icon"
				src="/images/help.svg"
				alt="Help"
				@click="help"
			>
		</div>

		<input
			v-if="field.type === 'number'"
			class="grid__input"
			type="text"
			v-model.number="field.value"
			:disabled="readonly"
			@input="updateValue"
		>

		<div
			v-if="field.type === 'checkbox'"
			class="grid__checkbox"
		>
			<input
				type="checkbox"
				:id="field.key"
				:checked="field.value"
				:disabled="readonly"
				@input="toggleCheckbox"
			>
		</div>

		<div class="grid__score">{{ isNaN(field.score) ? '-' : Math.round(field.score) }}</div>

		<div class="grid__rating"><i class="star" v-bind:class="starRating"></i></div>

	</div>

</template>

<script>
export default {
	props: {
		field: {
			type: Object,
			required: true,
		},
		measure: {
			type: String,
			required: true,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		starRating() {
			if (!isNaN(this.field.score)) {
				if (this.field.score / this.field.best >= 0.5) {
					return 'star--100';
				} else if (this.field.score >= 0) {
					return 'star--50';
				}
			}
			return 'star--0';
		}
	},

	methods: {

		updateValue() {
			this.$store.dispatch('updateField', {
				measure: this.measure,
				key: this.field.key,
				value: this.field.value
			});
		},

		toggleCheckbox() {
			console.log('click')
			if (this.readonly) return
			console.log('update')

			this.field.value = !this.field.value;
			this.updateValue();
		},

		help() {
			this.$emit('help', this.field);
		}

	}

}
</script>
