<template>
	<div class="box">

		<h2 class="chart__title">{{ title }}</h2>

		<div class="box__content">
			<div class="chart" :class="'chart--' + chartColor">
				<div class="chart__bar" :style="barStyle"></div>
				<div class="chart__score">
					<span class="chart__value">{{ score }}</span>
					/ {{ max}}
				</div>
			</div>

			<div class="chart__legend">
				{{ short }}<br>
				<span class="chart__description">{{ description }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {

	props: {
		title: String,
		short: String,
		description: String,
		score: Number,
		max: Number
	},

	data: function() {
		return {
			barStyle: {},
			chartColor: 'green',
			value: 0,
			percentage: 0,
			rotation: -180
		}
	},

	methods: {

		animateBar() {
			this.barStyle = {
				transform: `rotate(${this.rotation}deg)`
			}
		}

	},

	created() {
		this.value = Math.max(this.score, 0);
		this.percentage = this.value / this.max;
		this.rotation = Math.min(-180 + Math.round(this.percentage * 180), 0);

		if (this.percentage < 0.34) {
			this.chartColor = 'red';
		} else if (this.percentage < 0.67) {
			this.chartColor = 'yellow';
		} else {
			this.chartColor = 'green';
		}
	},

	mounted() {
		setTimeout(() => {
			this.animateBar();
		}, 100);
	}

}
</script>
