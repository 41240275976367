<template>
	<div class="minichart" :class="'minichart--' + chartColor">

		<div class="minichart__score">
			<span class="minichart__value">{{ score }}</span>
			/ {{ max}}
		</div>

		<div class="minichart__chart">
			<div class="minichart__bar" :style="barStyle"></div>
		</div>

	</div>
</template>

<script>
export default {

	props: {
		score: Number,
		max: Number
	},

	data: function() {
		return {
			barStyle: {},
			chartColor: 'green',
			value: 0,
			percentage: 0,
			rotation: -180
		}
	},

	watch: {

		score() {
			this.calculate();
			this.animateBar();
		}

	},

	methods: {

		animateBar() {
			this.barStyle = {
				transform: `rotate(${this.rotation}deg)`
			}
		},

		calculate() {
			this.value = Math.max(this.score, 0);
			this.percentage = this.value / this.max;
			this.rotation = Math.min(-180 + Math.round(this.percentage * 180), 0);

			if (this.percentage < 0.34) {
				this.chartColor = 'red';
			} else if (this.percentage < 0.67) {
				this.chartColor = 'yellow';
			} else {
				this.chartColor = 'green';
			}
		}

	},

	created() {
		this.calculate();
	},

	mounted() {
		setTimeout(() => {
			this.animateBar();
		}, 100);
	}

}
</script>
