const calculateScore = (field, value) => {
	let fn;

	// vybereme funkci pro výpočet skóre daného pole
	switch(field.fn) {
		case 'kpi':
			fn = kpi;
			break;
		case 'pd':
		case 'pp':
			fn = pdpp;
			break;
		case 'bool':
			fn = bool;
			break;
	}

	// zavoláme vybranou funkci a vrátíme vypočtené skóre
	return fn(field, value);
}


const isNumber = (value) => typeof value === 'number' && isFinite(value);


const kpi = (field, value) => {
	// hodnota, která je zadaná v původním Excelu
	// jako pojmenovaná konstanta
	const points = 20;

	if (!isNumber(value)) return null;

	if (value > points) {
		return field.worst;
	} else {
		return ((1 - (value / points)) * (field.best + Math.abs(field.worst)) + field.worst);
	}
}


const pdpp = (field, value) => {
	if (!isNumber(value)) return null;

	return ((value / 100) * (field.best + Math.abs(field.worst)) + field.worst)
}


const bool = (field, value) => {
	return value ? field.best : field.worst;
}


export default calculateScore;