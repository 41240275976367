<template>
  <div class="app" :style="transformCSS">

    <sidebar/>

    <div class="main-wrapper">

      <router-view v-slot={Component}>
        <transition
          :name="transitionName"
          mode="out-in"
        >
          <component :is="Component" />
        </transition>
      </router-view>

      <navigation v-if="$route.name!=='Report'" />

    </div>

  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import Navigation from '@/components/Navigation.vue';

const DEFAULT_TRANSITION = 'fade';

const APP_WIDTH = 1150;

export default {
  components: {
    sidebar: Sidebar,
    navigation: Navigation
  },

  data: function() {
    return {
      transitionName: DEFAULT_TRANSITION,
      windowSize: document.querySelector('html').clientWidth

    }
  },

  computed: {

    scale() {
      return APP_WIDTH < this.windowSize ? 1 : this.windowSize / APP_WIDTH;
    },

    transformCSS() {
      return `transform: scale(${ this.scale });`;
    }

  },

  methods: {

    resizeApp() {
      this.windowSize = document.querySelector('html').clientWidth;
    }

  },

  created() {
    window.addEventListener("resize", this.resizeApp);
    this.resizeApp();
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeApp);
  }

}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
</style>
