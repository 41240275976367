<template>
  <main class="main">

    <header-bar
      arrow="TALENT"
      short="PD"
      title="Personal Development"
      type="pd"
    />

    <grid measure="pd" />

  </main>
</template>


<script>
import Header from '@/components/Header.vue';
import Grid from '@/components/Grid.vue';

export default {
  components: {
    'header-bar': Header,
    'grid': Grid
  }
}
</script>