<template>
  <main class="main">

    <header-bar
      arrow="TECHNOLOGY"
      short="PP"
      title="Policy &amp; Procedures"
      type="pp"
    />

    <grid measure="pp" />

  </main>
</template>


<script>
import Header from '@/components/Header.vue';
import Grid from '@/components/Grid.vue';

export default {
  components: {
    'header-bar': Header,
    'grid': Grid
  }
}
</script>