<template>
  <div
    v-if="isOpen"
    class="popup"
  >
    <button
      class="button popup__close"
      @click="close"
    >
      <img src="/images/close.svg" alt="Close">
    </button>

    <h2 class="popup__title">{{ field.label }}</h2>
    <div class="popup__text">
      <div v-html="field.text"></div>
      <p>Score range is <strong>{{ field.worst }}</strong> to <strong>{{ field.best }}</strong>.</p>
    </div>


  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  methods: {
    close() {
      this.$emit('close-popup');
    }
  }
}
</script>
