<template>
  <aside class="sidebar">
    <img class="sidebar__logo" src="/images/logo.png" alt="Logo">
    <h1 class="sidebar__title">
      Callisto<br>
      Performance<br>
      Measure
    </h1>
  </aside>
</template>


