<template>
  <div>

    <popup
      v-if="!readonly"
      :isOpen="popupOpen"
      :field="popupField"
      @close-popup="hidePopup"
    />

    <div class="content">

      <section
        class="grid-section"
        v-for="section in gridData"
        :key="section.title"
      >
        <h3 class="grid-section__title">{{ section.title }}</h3>

        <div class="grid">

          <grid-item
            v-for="field in section.fields"
            :key="field.key"
            :field="field"
            :measure="measure"
            :readonly="readonly"
            @help="showPopup($event)"
          />

        </div>
      </section>

    </div>

  </div>
</template>


<script>
import Popup from '@/components/Popup.vue';
import GridItem from '@/components/GridItem.vue';

export default {
  components: {
    'popup': Popup,
    'grid-item': GridItem
  },

  props: {
    measure: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data: function() {
    return {
      popupOpen: false,
      popupField: {}
    }
  },

  computed: {
    gridData() {
      return this.$store.state[this.measure];
    },
  },

  methods: {
    hidePopup() {
      this.popupOpen = false;
    },

    showPopup(field) {
      // naplnit popup relevantnimi daty
      this.popupField = field;
      this.popupOpen = true;
    }

  },
}
</script>