import { createRouter, createWebHistory } from 'vue-router'

import Kpi from '../views/Kpi.vue'
import Pd from '../views/Pd.vue'
import Pp from '../views/Pp.vue'
import Rating from '../views/Rating.vue'
import Report from '../views/Report.vue'

const routes = [
  {
    path: '/',
    redirect: '/pd'
  },
  {
    path: '/pd',
    name: 'PD',
    component: Pd
  },
  {
    path: '/pp',
    name: 'PP',
    component: Pp
  },
  {
    path: '/kpi',
    name: 'KPI',
    component: Kpi
  },
  {
    path: '/rating',
    name: 'Rating',
    component: Rating
  },
  {
    path: '/report/:encodedCPMValues',
    name: 'Report',
    component: Report,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
