<template>
  <main class="main">

    <modal-email
      :isOpen="modalOpen"
      @close="hideModal"
    />

    <header-bar
      arrow="TALENT + TECHNOLOGY = EXCELLENCE"
      short=""
      title=""
      type=""
      :buttons="true"
    >
      <button
        class="button button--green"
        @click="showModal"
      >
        Submit
      </button>
    </header-bar>

    <cpm-rating :score="totalScore" />

    <div class="chart-grid">
      <score-chart title="Talent" short="PD" description="Personal Development" :score="pdScore" :max="pdMax" />
      <score-chart title="Technology"  short="PP" description="Policy &amp; Procedures" :score="ppScore" :max="ppMax" />
      <score-chart title="Excellence" short="KPI" description="Key Performance Indicators" :score="kpiScore" :max="kpiMax" />
    </div>

  </main>
</template>


<script>
import CpmRating from '@/components/CpmRating.vue';
import ScoreChart from '@/components/ScoreChart.vue';
import Header from '@/components/Header.vue';
import ModalEmail from '@/components/ModalEmail.vue';


export default {

  components: {
    'header-bar': Header,
    'cpm-rating': CpmRating,
    'score-chart': ScoreChart,
    'modal-email': ModalEmail,
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    kpi() {
      return this.$store.state.kpi;
    },

    pd() {
      return this.$store.state.pd;
    },

    pp() {
      return this.$store.state.pp;
    },

    kpiScore() {
      return this.calculateScore(this.kpi);
    },

    kpiMax() {
      return this.calculateMaxScore(this.kpi);
    },

    pdScore() {
      return this.calculateScore(this.pd);
    },

    pdMax() {
      return this.calculateMaxScore(this.pd);
    },

    ppScore() {
      return this.calculateScore(this.pp);
    },

    ppMax() {
      return this.calculateMaxScore(this.pp);
    },

    totalScore() {
      return this.kpiScore + this.pdScore + this.ppScore;
    }

  },

  methods: {

    calculateScore(data) {
      return Math.round(
        data.reduce(
          (total, section) => total + section.fields.reduce(
            (score, field) => score + field.score
            , 0)
          , 0)
        );
    },

    calculateMaxScore(data) {
      return Math.round(
        data.reduce(
          (total, section) => total + section.fields.reduce(
            (score, field) => score + field.best
            , 0)
          , 0)
        );
    },

    hideModal() {
      this.modalOpen = false;
    },

    showModal() {
      this.modalOpen = true;
    },

  }

}
</script>