<template>
	<nav class="nav">
		<div class="nav__back">
			<button
				class="button button--icon-left"
				v-show="!isFirstScreen"
				@click="goBack"
			>
				<img src="/images/arrow-left.svg" alt="">
				Back
			</button>
		</div>

		<div class="nav__steps">
			<router-link
				v-for="link in links"
				:key="link.route"
				:to="link.route"
				class="nav__step"
				active-class="nav__step--active"
			>
				{{ link.name }}
			</router-link>
		</div>

		<div class="nav__next">
			<button
				class="button button--icon-right"
				v-show="!isLastScreen"
				@click="goNext"
			>
				Next
				<img src="/images/arrow-right.svg" alt="">
			</button>
		</div>
	</nav>
</template>

<script>
export default {
	data: function() {
		return {
			links: [
				{ name: 'PD', route: '/pd' },
				{ name: 'PP', route: '/pp' },
				{ name: 'KPI', route: '/kpi' },
				{ name: 'Rating', route: '/rating' }
			],
			screenIndex: null
		}
	},

	computed: {
		isFirstScreen() {
			return this.screenIndex === 0;
		},

		isLastScreen() {
			return this.screenIndex === this.links.length - 1;
		}
	},

	watch: {
		$route(to, from) {
			this.getCurrentScreen(to);
		}
	},

	methods: {
		getCurrentScreen(route) {
			this.screenIndex = this.links.findIndex(link => link.name === route.name);
		},

		goBack() {
			if (this.screenIndex !== 0) {
				this.$router.push({ name: this.links[this.screenIndex - 1].name });
			}
		},

		goNext() {
			if (this.screenIndex < this.links.length) {
				this.$router.push({ name: this.links[this.screenIndex + 1].name });
			}
		}
	},

	mounted() {
		this.getCurrentScreen(this.$router.currentRoute);
	}

}
</script>
