<template>
  <main class="main">

    <header-bar
      arrow="CPM Report"
      short=""
      title=""
      type=""
      :buttons="true"
    >
      <button
        class="button button--green"
        @click="print"
      >Print / Export to PDF</button>

      <button
        class="button"
        @click="editValues"
      >Edit values</button>
    </header-bar>

    <div class="print-instructions">
      <div class="box">
        <div class="box__content">
          <p>To export the report to PDF, press the <strong>Print</strong> button and select the <strong>Save as PDF</strong><br>as a target printer in the printing dialog box.</p>
        </div>
      </div>
    </div>


    <cpm-rating :score="totalScore" />

    <div class="chart-grid">
      <score-chart title="Talent" short="PD" description="Personal Development" :score="pdScore" :max="pdMax" />
      <score-chart title="Technology"  short="PP" description="Policy &amp; Procedures" :score="ppScore" :max="ppMax" />
      <score-chart title="Excellence" short="KPI" description="Key Performance Indicators" :score="kpiScore" :max="kpiMax" />
    </div>

    <header-bar
      arrow="TALENT"
      short="PD"
      title="Personal Development"
      type="pd"
      class="header--margin-top"
    />

    <grid measure="pd" :readonly="true" />

    <header-bar
      arrow="TECHNOLOGY"
      short="PP"
      title="Policy &amp; Procedures"
      type="pp"
      class="header--margin-top"
    />

    <grid measure="pp" :readonly="true" />

    <header-bar
      arrow="EXCELLENCE"
      short="KPI"
      title="Key Performance Indicators"
      type="kpi"
      class="header--margin-top"
    />

    <grid measure="kpi" :readonly="true" />
  </main>
</template>


<script>
import CpmRating from '@/components/CpmRating.vue';
import ScoreChart from '@/components/ScoreChart.vue';
import Header from '@/components/Header.vue';
import Grid from '@/components/Grid.vue';


export default {

  components: {
    'header-bar': Header,
    'cpm-rating': CpmRating,
    'score-chart': ScoreChart,
    'grid': Grid,
  },

  props: {
    encodedCPMValues: {
      type: String,
      required: true,
    }
  },

  computed: {
    kpi() {
      return this.$store.state.kpi;
    },

    pd() {
      return this.$store.state.pd;
    },

    pp() {
      return this.$store.state.pp;
    },

    kpiScore() {
      return this.calculateScore(this.kpi);
    },

    kpiMax() {
      return this.calculateMaxScore(this.kpi);
    },

    pdScore() {
      return this.calculateScore(this.pd);
    },

    pdMax() {
      return this.calculateMaxScore(this.pd);
    },

    ppScore() {
      return this.calculateScore(this.pp);
    },

    ppMax() {
      return this.calculateMaxScore(this.pp);
    },

    totalScore() {
      return this.kpiScore + this.pdScore + this.ppScore;
    }

  },

  methods: {

    print() {
      window.print();
    },

    editValues() {
      this.$router.push({path: '/'})
    },

    calculateScore(data) {
      return Math.round(
        data.reduce(
          (total, section) => total + section.fields.reduce(
            (score, field) => score + field.score
            , 0)
          , 0)
        );
    },

    calculateMaxScore(data) {
      return Math.round(
        data.reduce(
          (total, section) => total + section.fields.reduce(
            (score, field) => score + field.best
            , 0)
          , 0)
        );
    },

  },

  created() {
    if (!this.encodedCPMValues) return

		this.$store.dispatch('decodeAndSetCPMValues', this.encodedCPMValues);
  },
}
</script>