<template>
  <div class="popup-bg" v-if="isOpen"></div>
  <div
    v-if="isOpen"
    class="popup popup--no-min-height"
  >
    <button
      class="button popup__close"
      @click="close"
    >
      <img src="/images/close.svg" alt="Close">
    </button>

    <h2 class="popup__title">Send report via e-mail</h2>

    <template v-if="isDone">
      <div class="popup__text popup__text--center">
        <p>Report has been successfully sent.</p>
        <button class="button button--green" @click="close">OK</button>
      </div>
    </template>

    <template v-else>
      <div class="popup__text">
        <p>A link to the report with an overview of all entered values and the final evaluation will be sent. The report can be printed or exported to PDF afterwards.</p>
        <p>Enter your name and email address. Once you hit <strong>Send report</strong> button, the e-mail will be sent to you and Callisto Grand at the same time.</p>
      </div>
      <div class="popup__error" v-if="error">{{ error }}</div>
      <div class="popup__form">

        <label for="email">Name</label>
        <input
          type="text"
          id="jmeno"
          v-model="jmeno"
          @keyup="error = ''"
        />

        <label for="email">E-mail</label>
        <input
          type="text"
          id="email"
          v-model="email"
          @keyup="error = ''"
        />

        <button
          class="button button--green"
          :disabled="isSending"
          @click="sendReport"
        >
          {{ isSending ? "Sending..." : "Send report" }}
        </button>

      </div>
    </template>
  </div>
</template>

<script>
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'

export default {
  emits: ['close'],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      error: '',
      email: '',
      jmeno: '',
      isSending: false,
      isDone: false,
    }
  },

  computed: {
    encodedCPMValues() {
      return this.$store.getters.encodedCPMValues;
    }
  },

  methods: {
    close() {
      this.$emit('close');
      this.error = ''
      this.email = ''
      this.jmeno = ''
      this.isSending = false
      this.isDone = false
    },

    async sendReport() {
      const jmeno = this.jmeno.trim();
      const email = this.email.trim();

      // validace
      if (jmeno === '') {
        this.error = 'Enter name'
        return
      }
      if (!isEmail(email)) {
        this.error = 'Enter valid e-mail address'
        return
      }

      try {
        const MAILER_URL = 'https://cpm.rolecek.cz/mail/'

        const data = {
          name: jmeno,
          email: email,
          data: this.encodedCPMValues
        }

        // odeslani emailu
        this.isSending = true
        const response = await axios.post(MAILER_URL, data)
        this.isSending = false

        if (!response.data.success) {
          this.error = response.data.error
          return
        }

        // uspesne odeslano
        this.isDone = true

      } catch (error) {
        this.error = error.message
        return
      }
    }
  }
}
</script>
