<template>
	<div class="cpm">
		<div class="cpm-chart">

			<svg
				:style="needleStyle"
				class="cpm-chart__needle" viewbox="0 0 210 21" width="210" height="21"
			>
				<path fill="#000000" d="M0 0 L210 11 L0 21 Z"/>
			</svg>

			<div class="cpm-chart__circle">
				<div class="cpm-chart__label">RATING</div>
				<div class="cpm-chart__value" :class="`cpm-chart__value--${rating}`">
					<div class="cpm-chart__score">{{ score }}</div>
					<div class="cpm-chart__rating">{{ rating === 'none' ? 'no rating' : rating }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

	props: {
		score: Number
	},

	data: function() {
		return {
			needleStyle: {},
			rotation: -180
		}
	},

	computed: {
		rating() {
			if (this.score > 162) {
				return 'platinum';
			} else if (this.score > 144) {
				return 'gold';
			} else if (this.score > 126) {
				return 'silver';
			} else if (this.score > 108) {
				return 'bronze';
			} else {
				return 'none';
			}
		}
	},

	methods: {
		animateNeedle() {
			this.needleStyle = {
				transform: `rotate(${this.rotation}deg)`
			}
		}
	},

	created() {
		this.rotation = -180 + Math.min(180, Math.max(0, this.score));
	},

	mounted() {
		setTimeout(() => {
			this.animateNeedle();
		}, 100);
	}

}
</script>
